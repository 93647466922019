import { Component, Mixins } from "vue-property-decorator";
import ModelMixin from "@/mixins/ModelMixin";
import type { VueClass } from "vue-class-component/lib/declarations";
import type { CompanyData } from "@planetadeleste/vue-mc-gw";
import {
  Company,
  CompanyCollection,
  CompanySettings,
} from "@planetadeleste/vue-mc-gw";
import { AppModule } from "@/store/app";
import { delay, toString } from "lodash";
import type { ResultData } from "vue-mc";

const TypedModelMixin = ModelMixin as unknown as VueClass<
  ModelMixin<Company, CompanyCollection>
>;

@Component
export default class CompaniesMixin extends Mixins(TypedModelMixin) {
  obCollection = new CompanyCollection();
  obModelClass = Company;
  obCollectionClass = CompanyCollection;
  sRouteName = "companies.list";

  created() {
    this.onCreated();
  }

  saving() {
    this.continueEditing = !this.$route.params.id;
  }

  async saved(obResponse: ResultData<CompanyData>) {
    if (this.continueEditing && obResponse.data.id) {
      this.continueEditing = false;
      await this.$router.push({
        name: "companies.update",
        params: { id: toString(obResponse.data.id) },
      });
    }
  }

  onRegisterEvents() {
    this.addEvent(`${this.sModelName}.before.sync`, (obModel: Company) => {
      const obSettings = obModel.get("settings");
      if (obSettings instanceof CompanySettings) {
        obModel.set("settings", obSettings.attributes);
      }
    });

    this.addEvent(
      `${this.sModelName}.after.save`,
      (obCompanyData: CompanyData) => {
        if (AppModule.company && obCompanyData.id === AppModule.company.id) {
          const obBranch = AppModule.branch;

          AppModule.pickCompany(obCompanyData);
          if (obBranch) {
            AppModule.pickBranch(obBranch.id);
          }
        }

        this.emit("reload.index");
      }
    );

    this.addEvent("after.hide.form", () => {
      delay(() => {
        this.emit("reload.index");
      }, 1500);
    });
  }
}
